<template>
    <div class="container">
        <alert/>
    </div>
    <div id="container">
        <div class="columns">
            <div class="column pl-30 pt-30 pb-0 restrict-height">
                <D3AChatBotStandalone @pageClicked="handlePageClicked"/>
            </div>
            <div v-if="isAIModalOpen" class="column mobile-hidden">
                <D3APDFViewerSearch :pageNumber="pageNumber"/>
                <div class="adobe-dc-view-search"
                    :id="'AI-adobe-dc-view-' + documentParamDocId"
                    :data-previd="'document-prev-button-' + documentParamDocId"
                    :data-nextid="'document-next-button-' + documentParamDocId"
                    :data-indexid="'document-search-index-' + documentParamDocId"
                    :data-totalcountid="'document-search-total-count-' + documentParamDocId"
                    :data-url="fileUrl"
                    :data-filename="titleize(title)"
                    :data-embedmode="'SIZED_CONTAINER'"
                    :data-searchterm="searchTerm"
                    :data-exact="exact"
                    style="height: 500px; width: 100%;"
                ></div>
            </div>
        </div>
    </div>
    <!-- End Page: pages/chat-bot -->
</template>

<script>
//import RequestHandler from "@/handler/RequestHandler";
import Breadcrumbs from "./breadcrumbs.vue";
import Alert from "./alert.vue";
import Preloader from "./preloader.vue";
//import {ref, watch, computed} from "vue";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//import { useStore } from "vuex";-
//import CustomSelect from "./custom-select";
//import MarkdownIt from "markdown-it";
//import DocumentInfoCard from "./document-info-card";
//import {marked} from "marked";

import { reactive, ref, watch, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from "vuex";
import InfoCard from "./info-card";
import ReadMoreLink from "./read-more-link";
import { priceFormat } from "@/helpers/filter";
import TagVendor from "./tag--vendor";
import TagAgency from "./tag--agency";
import Tag from "./tag";
import TagBudget from "./tag--budget";
import IframeModal from "./iframe-modal";
import Tooltip from "./tooltip";
import Faqs from "./faqs";
import D3APDFViewerSearch from "./d3a-pdf-viewer-search";
import RequestHandler from "@/handler/RequestHandler";
import { useRoute, useRouter } from 'vue-router';
import D3AChatBotStandalone from "./d3a-chat-bot-standalone";


export default {
    name: "chat-bot",
    components: {
        Alert,
        Breadcrumbs,
        Preloader,
        FontAwesomeIcon,
        InfoCard,
        ReadMoreLink,
        TagVendor,
        TagAgency,
        Tag,
        TagBudget,
        IframeModal,
        Tooltip,
        Faqs,
        D3APDFViewerSearch,
        D3AChatBotStandalone
    },
    props: {
        searchTerm: String,
        contentId: Number,
        locationId: Number,
        title: String,
        type: String,
        id: String,
        year: String,
        agency: Object,
        category: String,
        fileUrl: String,
        highlight: String,
        exact: Boolean,
        showAiBtn: Boolean,
    },
    data() {
        return {
            modalClassPresent: false
        };
    },
    computed: {
        highlightedCategory() {
            if (this.category && this.category.includes('Amended')) {
            return this.category.replace('Amended', '<span class="amended-highlight">Amended</span>');
            } else {
            return this.category;
            }
        }
    },
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const documentParamId = false;
        const documentParamDocId = route.params.docid;
        const documentDetail = ref(null);
        const showIframe = ref(false);
        const uploadError = ref(false);
        const uploadErrorMessage = ref('');
        const store = useStore();
        const definitions = computed(() => store.getters.definitions);
        const bodyElm = document.body;
        const selectedDocumentContent = ref(null);
        const pageNumber = ref(false)

        const newIframeCreated = () => {
            showIframe.value = false;
        };

        const isModalOpen = ref(false);
        const openModal = (ev) => {
            ev.preventDefault();
            isModalOpen.value = true;
            //handleScroll();
        };

        const isAIModalOpen = ref(false);
        const openAIModal = (contentId) => {

        };

        const closeModal = () => {
            isModalOpen.value = false;
            isAIModalOpen.value = false;

            // undo the scroll prevention
            document.body.style.overflow = '';
            document.body.style.position = '';
            // find the desired scroll position and make sure the user is put there
            const scrollY = -parseInt(document.body.style.top);
            document.body.style.top = '';
            window.scrollTo(0, scrollY);

            window.removeEventListener('keydown', window.searchPrevListener);
        };

        const handleScroll = (type) => {
            // prevent scrolling while keeping user's scroll position
            const scrollY = window.scrollY;
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.top = `-${scrollY}px`;

            if (type == 'ai') {
                window.searchDataId = 'AI-adobe-dc-view-' + props.contentId;
            } else {
                window.searchDataId = 'adobe-dc-view-' + props.contentId;
            }

            // on left arrow, call searchPrevious
            // on right arrow, call searchNext
            window.searchPrevListener = function(e) {
                if (e.keyCode == 37) {
                    window.searchPrevious(window.searchDataId);
                }
                if (e.keyCode == 39) {
                    window.searchNext(window.searchDataId);
                }
            };
            window.addEventListener('keydown', window.searchPrevListener);
        }

        onMounted(() => {
            document.querySelectorAll('#main-content')[0].classList.remove('container');
        });
        onUnmounted(() => {
            document.querySelectorAll('#main-content')[0].classList.add('container');
        });

        const loadDocument = (contentId, standalone, intPageNumber) => {
            RequestHandler.loadD3ADocument(contentId).then(data => {
                if(data && data.DocumentRef) {
                    documentDetail.value = data.DocumentRef;
                    isAIModalOpen.value = true;
                    props.fileUrl = documentDetail.value.fileUrl;
                    props.title = documentDetail.value.title;
                    console.log('complete');
                    pageNumber.value = contentId + "|" + intPageNumber;
                }
            }).catch((error) => {
                uploadError.value = true;
                isAIModalOpen.value = false;
                uploadErrorMessage.value = error;
                setTimeout(() => {
                    uploadErrorMessage.value = '';
                    uploadError.value = false;
                }, 60000);
            });
        };

        const handlePageClicked = (page, context) => {
            let [contentId, intPageNumber] = page.split('|');
            openAIModal(contentId);
            if (selectedDocumentContent.value !== contentId) {
                selectedDocumentContent.value = contentId
                loadDocument(contentId, false, intPageNumber);
            } else {
                pageNumber.value = page;
            }
        };

        return {
            priceFormat,
            newIframeCreated,
            showIframe,
            definitions,
            isModalOpen,
            openModal,
            closeModal,
            loadDocument,
            uploadError,
            isAIModalOpen,
            openAIModal,
            handlePageClicked,
            pageNumber
        };
    },
    mounted: function () {
        this.columnize();

        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    const body = document.querySelector('body')
                    this.modalClassPresent = body.classList.contains('ViewSDK_hideOverflow')
                }
            })
        })
        const options = {
            attributes: true
        }
        const body = document.querySelector('body');
        observer.observe(body, options)
    },
    watch: {
        modalClassPresent(newVal) {
            if (!newVal) {
                this.closeModal();
            }
        }
    },
    methods: {
        triggerAccordion(ev) {
            let contentId = this.contentId;

            // array of all triggers
            let triggers = Array.from(document.querySelectorAll('.accordion-' + contentId + ' [data-toggle="collapse"]'));
            // the button triggered
            const elm = ev.target;

            // find all the triggerable items
            const items = Array.from(document.querySelectorAll('.accordion-' + contentId + ' .accordion-btn'));
            items.forEach(target => {
                // if the button is is the same as the one just triggered
                if (target === elm) {
                    // skip this one, it is the one triggered
                } else { // otherwise
                    // close the item
                    target.nextElementSibling.classList.remove('show');
                    // reset the icons on the button
                    target.querySelector('.header-icon--closed').classList.add('shown');
                    target.querySelector('.header-icon--open').classList.remove('shown');
                }
            });

            // switch icons in the button
            var openIcon = elm.querySelector('.accordion-' + contentId + ' .header-icon--closed');
            var closeIcon = elm.querySelector('.accordion-' + contentId + ' .header-icon--open');
            closeIcon.classList.toggle('shown');
            openIcon.classList.toggle('shown');

            // if the button triggered is inside of the array of all the triggers (this should always be true)
            if (triggers.includes(elm)) {
                // get the content related to the trigger button
                const selector = elm.getAttribute('data-target');
                const targets = Array.from(document.querySelectorAll(selector));

                const fnmap = {
                    'toggle': 'toggle',
                    'show': 'add',
                    'hide': 'remove'
                };

                // open the selected one
                targets.forEach(target => {
                    target.classList[fnmap['toggle']]('show');
                });
            }
        },
        resetAccordions() {
            let contentId = this.contentId;

            // find all the triggerable items
            const items = Array.from(document.querySelectorAll('.accordion-' + contentId + ' .accordion-btn'));
            items.forEach(target => {
                // close the item
                target.nextElementSibling.classList.remove('show');
                // reset the icons on the button
                target.querySelector('.accordion-' + contentId + ' .header-icon--closed').classList.add('shown');
                target.querySelector('.accordion-' + contentId + ' .header-icon--open').classList.remove('shown');
            });
        },
        getHighlightItems: function (highlights) {
            let highlightsSplit = this.getHighlights(highlights);
            let items = [];
            for (let i = 0; i < highlightsSplit.length; i++) {
                let item = {
                    name: 'Match ' + (i + 1),
                    children: [
                        {
                            content: highlightsSplit[i],
                        },
                    ],
                };
                items.push(item);
            }
            return items;
        },
        getHighlights: function (highlight) {
            return highlight.split('~MYGOVGUIDE_ENDOFMATCH~');
        },
        titleize: function(item) {
            if (!item) {
                return item;
            }
            return item.toLowerCase().replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
        },
        columnize: function() {

            // forEach function
            var forEach = function (array, callback, scope) {
                for (var i = 0; i < array.length; i++) {
                callback.call(scope, i, array[i]); // passes back stuff we need
                }
            }

            // select all .parent divs
            var parentDivs = document.querySelectorAll('.parent');

            //slicing the array
            var chunk = function ( array, size ) {
                var arr = [];
                for ( var i = 0; i < array.length; i += size ) {
                    var newSlicedArray = Array.prototype.slice.call( array, i, i + size );
                    arr.push(newSlicedArray);
                }
                return arr;
            }

            //run foreach function
            forEach(parentDivs, function (index, value) {

                var childrens = value.querySelectorAll(".create-rows");

                var final = chunk(childrens,4);
                final.map( towrap =>
                    towrap.reduce( (acc, el) =>
                        (acc.appendChild(el),acc) , document.createElement('div') )
                ).forEach( el => {
                    el.className ="columns created-cols";
                    value.appendChild(el)
                })

            });

        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    @import "../../assets/scss/partials/_accordion-checkbox.scss";

    #container .columns {
        margin: 0;
    }

    #container .column:last-child {
        padding: 0;
    }

    .no-scroll {
        height: 100%;
        overflow: hidden;
    }

    .restrict-height {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 46px);
        background: #eff5fa;
    }

    .mb-0 {
        margin-bottom: 0px !important;
    }

    .card__info__label.card__info__label__link {
        font-size: 15px;
    }

    .card__info__data--highlight {
        margin-bottom: 0em;
    }

    .header-icon {
        display: none;
        &.shown {
            display: inline-block;
        }
    }

    .header-title, .header-icon, .header-icon--open {
        pointer-events: none;
    }

    .header-title {
        display: inline-block;
        font-weight: 600;
        font-size: 28px;
        margin-left: 10px;
        margin-bottom: 0px;
        position: relative;
        top: -5px;
    }

    .faq-item__container {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .faq-item {
        border: 3px solid $blue--med;
        width: 100%;
        transition: all 0.3s ease-in-out;
        background-color: $white;
        border-radius: 6px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        .accordion-label {
            .header-icon, .header-icon--open {
                padding-right: 15px;
            }
            .header-title {
                font-size: 28px;
                margin-bottom: 0px;
            }
        }
        .accordion-content {
            padding-top: 0px;
            .column {
                padding-top: 0px;
            }
        }
    }

    .faq-content {
        padding: 20px;
    }

    .collapse {
        display: block;
        max-height: 0px;
        overflow: hidden;
        transition: max-height .5s cubic-bezier(0, 1, 0, 1);

        &.show {
            max-height: 99em;
            transition: max-height .5s ease-in-out;
        }
    }

    .accordion-btn {
        background-color: transparent;
        border: none;
        margin-top: 15px;
    }

    .card__info__data .icon {
        margin-right: 10px;
    }

    pre b {
        background-color: $blue--med;
        color: white;
    }
    

    .adobe-dc-view-search {
        height: 88vh !important;
        > iframe {
            height: 88vh !important;
        }
    }

    .amended-highlight {
        color: $red;
    }

    .sparkle {
        margin-right: 5px;
    }
    .sparkle img {
        width: 24px;
        height: 24px;
    }

    .error-message {
        color: $red
    }
</style>
