<script>
    export default {
        name: 'D3APDFViewerSearch',
        props: {
            pageNumber: {
                type: String,
                default: null,
            },
        },
        watch: {
            pageNumber(newVal, oldVal) {
                let [newContentId, newPageNumber] = newVal.split('|');
                let [oldContentId, oldPageNumber] = oldVal.split('|');
                if (oldContentId != newContentId) {
                    this.renderPdf(parseInt(newPageNumber));
                } else {
                    this.navigateToPage(parseInt(newPageNumber));
                }
            },
        },
        data () {
            return {
                previewFilePromise: null,
                adobeViewer: null
            }
        },
        mounted() {
            const [contentId, pageNumber] = this.pageNumber.split('|');
            this.renderPdf(parseInt(pageNumber));
        },
        methods: {
            navigateToPage(pageNumber) {
                this.adobeViewer.getAPIs().then(apis => {
                    // Jump to the specific page
                    apis.gotoLocation(pageNumber)
                });
            },
            renderPdf(pageNumber = 1) {
                let pdf = document.getElementsByClassName('adobe-dc-view-search');
                for (let i = 0; i < pdf.length; i++) {
                    let dataUrl = pdf[i].getAttribute('data-url') + 'f';
                    let dataFileName =  pdf[i].getAttribute('data-filename');
                    let dataEmbedMode = pdf[i].getAttribute('data-embedmode');
                    let dataId =  pdf[i].getAttribute('id');
                    let dataSearchTerm = pdf[i].getAttribute('data-searchterm');
                    let dataPrevId = pdf[i].getAttribute('data-previd');
                    let dataNextId = pdf[i].getAttribute('data-nextid');
                    let dataIndexId = pdf[i].getAttribute('data-indexid');
                    let dataTotalCountId = pdf[i].getAttribute('data-totalcountid');
                    let dataExact = pdf[i].getAttribute('data-exact');

                    //start adobe view
                    let adobeDCView = new AdobeDC.View({
                        clientId: process.env.VUE_APP_ADOBE_KEY,
                        divId: dataId
                    })
                    // send data to adobe api
                    const viewerConfig = {
                        embedMode: dataEmbedMode,
                        showFullScreen: true,
                        showPrintPDF: true,
                        showDownloadPDF: true,
                        dockPageControls: true,
                        defaultViewMode: "FIT_PAGE",
                        borderless: false,
                        enableSearchAPIs: true, // https://blog.developer.adobe.com/using-the-search-api-in-adobe-pdf-embed-api-74e319d78da2
                        // if we switch to LIGHTBOX search API needs to be false if you want the document search feature to appear
                    };

                    this.previewFilePromise = adobeDCView.previewFile(
                        {
                            content: {
                                location: {
                                    url: dataUrl
                                }
                            },
                            metaData: {
                                fileName: dataFileName
                            }
                        },
                        viewerConfig
                    ).then((adobeViewer) => {
                        this.adobeViewer = adobeViewer;
                        adobeViewer.getAPIs().then((apis) => {
                            apis.gotoLocation(pageNumber)
                                .then(() => {
                                    console.log(`Successfully navigated to page ${pageNumber}`);
                                })
                                .catch((error) => {
                                    console.error("Error navigating to page: ", error);
                                });
                        }).catch((error) => {
                            console.error("Error accessing APIs: ", error);
                        });
                    }).catch((error) => {
                        console.error("Error loading PDF: ", error);
                    });

                    if (dataSearchTerm) {
                        this.previewFilePromise.then(adobeViewer => {
                            adobeViewer.getAPIs().then(apis => {
                                apis.search(dataSearchTerm).then(searchObject => {
                                    window['searchObject-' + dataId] = searchObject;
                                    window['searchObject-' + dataId].onResultsUpdate(onResultsUpdate)
                                    .then(result => console.log(result))
                                    .catch(error => console.log(error));
                                })
                                .catch(error => console.log(error));
                            });
                        });
                    }

                    function onResultsUpdate(result) {
                        // TODO: not firing for some reason
                        updateSearchLocation(result)
                    }

                    function updateSearchLocation(result) {
                        // if (document.getElementById(dataIndexId)) {
                        //     document.getElementById(dataIndexId).innerHTML = result.currentResult.index;
                        // }
                        // if (document.getElementById(dataTotalCountId)) {
                        //     document.getElementById(dataTotalCountId).innerHTML = result.totalResults;
                        // }
                    }

                    function searchNext(dataId) {
                        if (window['searchObject-' + dataId]) {
                            window['searchObject-' + dataId].next();
                        }
                    }
                    window.searchNext = searchNext;

                    function searchPrevious(dataId) {
                        if (window['searchObject-' + dataId]) {
                            window['searchObject-' + dataId].previous();
                        }
                    }
                    window.searchPrevious = searchPrevious;

                    if (document.getElementById(dataPrevId)) {
                        document.getElementById(dataPrevId).addEventListener("click", searchPrevious);
                    }
                    if (document.getElementById(dataNextId)) {
                        document.getElementById(dataNextId).addEventListener("click", searchNext);
                    }
                }
            }
        }
    }
</script>