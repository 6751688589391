<template>
  <span class="responseLineHidden" v-html="fullHtmlText" ref="dynamicElement"></span>
  <span class="responseLine"></span>
</template>

<script>
import MarkdownIt from "markdown-it";

export default {
  props: {
    data: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      fullHtmlText: ''
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.processText(newValue);
        }
      }
    }
  },
  emits: ['pageClicked', 'typingStopped'],
  methods: {
    stopTyping() {
      console.log('Stop!');
      window.isTyping = false;  // Set global variable
    },
    emitPageJump(pageNumber) {
      this.$emit('pageClicked', pageNumber);
    },
    processText(text) {
      const markdown = new MarkdownIt({ html: true });
      this.fullHtmlText = markdown.render(text).replace(/pages?\s(\d+)\|(\d+)((?:\sand\s\d+)?(?:,\s*\d+)*(?:,\sand\s\d+)*)*/g, (match, docId, page1, rest) => {
        let result = `page <a href="#" class="chatinnertext" data-page="${docId}|${page1}">page ${page1}</a>`;

        if (rest) {
          let pages = rest.split(/(?:\sand\s|,\s*,|,\s*)/).map(page => page.trim());
          pages.forEach(page => {
            if (page) result += `, <a href="#" class="chatinnertext" data-page="${docId}|${page}">page ${page}</a>`;
          });
        }

        return result;
      });
    },
    doTyping(element) {
      const visibleDiv = element.nextElementSibling;
      const hiddenContent = element;
      const typingSpeed = 0;
      const batchSize = 10;

      const nodes = Array.from(hiddenContent.childNodes);
      let index = 0;

      const typeNextNode = () => {
        if (index < nodes.length && window.isTyping) {
          const currentNode = nodes[index];
          if (currentNode.nodeType === Node.ELEMENT_NODE) {
            const newElement = document.createElement(currentNode.nodeName);
            if (currentNode.nodeName === 'A') {
              newElement.href = currentNode.getAttribute('href');
              newElement.className = currentNode.className;
              newElement.setAttribute('data-page', currentNode.getAttribute('data-page'));
            }
            visibleDiv.appendChild(newElement);

            typeChildNodes(currentNode.childNodes, newElement, () => {
              if (newElement.nodeName === 'A') {
                newElement.addEventListener('click', (e) => {
                  e.preventDefault();
                  this.emitPageJump(newElement.getAttribute('data-page'));
                });
              }
              index++;
              typeNextNode();
            });

          } else if (currentNode.nodeType === Node.TEXT_NODE) {
            typeTextNode(currentNode, visibleDiv, () => {
              index++;
              typeNextNode();
            });
          }
        } else {
          if (visibleDiv.parentNode.parentNode.className == "messageFromChatGpt") {
            this.$emit('typingStopped');
          }
        }
      };

      const typeChildNodes = (childNodes, parentElement, callback) => {
        let childIndex = 0;

        const typeChild = () => {
          if (childIndex < childNodes.length && window.isTyping) {
            const childNode = childNodes[childIndex];

            if (childNode.nodeType === Node.ELEMENT_NODE) {
              const newElement = document.createElement(childNode.nodeName);
              if (childNode.nodeName === 'A') {
                newElement.href = childNode.getAttribute('href');
                newElement.className = childNode.className;
                newElement.setAttribute('data-page', childNode.getAttribute('data-page'));
              }
              parentElement.appendChild(newElement);

              typeChildNodes(childNode.childNodes, newElement, () => {
                if (newElement.nodeName === 'A') {
                  newElement.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.emitPageJump(newElement.getAttribute('data-page'));
                  });
                }
                childIndex++;
                typeChild();
              });

            } else if (childNode.nodeType === Node.TEXT_NODE) {
              typeTextNode(childNode, parentElement, () => {
                childIndex++;
                typeChild();
              });
            }
          } else {
            callback();
          }
        };

        typeChild();
      };

      const typeTextNode = (textNode, parentElement, callback) => {
        let textContent = textNode.textContent;
        let charIndex = 0;
        const typeChar = () => {
          if (charIndex < textContent.length && window.isTyping) {
            // Append batch of characters at once
            const chunk = textContent.slice(charIndex, charIndex + batchSize);
            parentElement.appendChild(document.createTextNode(chunk));
            charIndex += batchSize;
            setTimeout(typeChar, typingSpeed);
          } else {
            callback();
          }
        };
        typeChar();
      };

      // Start typing
      window.isTyping = true;  // Set to true when typing begins
      typeNextNode();
    }
  },
  mounted() {
    this.doTyping(this.$refs.dynamicElement);
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

    .responseLineHidden {
        display: none;
    }

    .responseLine h3 {
        font-size: 1.3em;
    }

    .responseLine ul {
        list-style: disc;
        margin-bottom: 12px;
    }

    .responseLine ul li {
        margin-left: 15px;
    }

</style>
